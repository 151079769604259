*::-webkit-scrollbar {
  display: block;
  opacity: 0.5;
  width: 7px;
  height: 7px;
  border-radius: 15px;
  overflow: hidden;
  transition: 300ms;
}

*:hover::-webkit-scrollbar {
  opacity: 1;
}

*:hover::-webkit-scrollbar-thumb {
  opacity: 1;
}

*::-webkit-scrollbar-thumb {
  width: auto;
  height: auto;
  background-color: #00000040;
  transition: 300ms;
  border-radius: 15px;
  position: absolute;
  opacity: 0;
}

*::-webkit-scrollbae-thumb:hover {
  background-color: #00000060;
}

.navbar {
  background-color: #0097B1;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* overflow: hidden; */
  width: 100%;
  top: 0;
  position: fixed;
}

a:hover {
  color: #FFFFFF;
}

.navbar-brand,
.navbar-nav {
  color: #FFFFFF;
  font-size: 16px !important;
}

.nav-item a:link {
  color: #FFFFFF;
  text-decoration: none;
}

.pro-sidebar>.pro-sidebar-inner {
  background-color: #0097B1 !important;
  overflow: hidden !important;
  position: fixed;
  height: 100%;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  font-size: 20px !important;
}

.pro-sidebar {
  font-size: 20px !important;
  color: #333 !important;
  width: 220px !important;
  min-width: 220px !important;
  position: fixed !important;
  overflow: hidden !important;
  height: 100% !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  color: #FFFFFF;
  font-size: 14px !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background-color: #0097b1 !important;
}

.pro-arrow {
  border-color: #FFFFFF !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
  outline: none;
  color: #333 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: #333 !important;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  background-color: #0097b1 !important;
}

.pro-sidebar.collapsed {
  width: 50px !important;
  min-width: 50px !important;
  position: fixed;
  overflow: hidden;
  height: 100%;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-top: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 8px 35px 8px 5px !important;
}

.nav-link,
.nav-item:hover,
.nav-link:focus {
  color: #FFFFFF !important;
}

.dropdown-item.active {
  color: #333;
  background-color: #FFFFFF;
}

.dropdown-item.active:hover {
  background-color: #0097b1;
  color: #FFFFFF;
}