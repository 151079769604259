#bcQ1Sheet,
#bcQ2Sheet,
#bcQ3Sheet,
#bcQ4Sheet {
  min-height: 75vh;
  margin-left: 5px;
}

.bcQuarter .wj-cell {
  text-align: center;
  font-size: xx-small;
  white-space: normal;
}

#s .wj-flexsheet .wj-cell {
  padding: 1px;
}

.col-2>button {
  height: 4.7vh;
}

.btnText {
  vertical-align: middle;
}

.col-2>button {
  height: 30px;
}

.btnText {
  vertical-align: middle;
}

.rowsBtn {
  width: 87px;
  height: 28px;
}

.columnsBtn {
  width: 105px;
  height: 28px;
}

.zoomClass {
  -webkit-transform: scale(1.5);
  -webkit-transform-origin: 0 0;
  -ms-transform: scale(1.5);
  -ms-transform-origin: 0 0;
  -moz-transform: scale(1.5);
  -moz-transform-origin: 0 0;
  transform: scale(1.5);
  transform-origin: 0 0;
}

.zoomClass1 {
  -webkit-transform: scale(2.5);
  -webkit-transform-origin: 0 0;
  -ms-transform: scale(2.5);
  -ms-transform-origin: 0 0;
  -moz-transform: scale(2.5);
  -moz-transform-origin: 0 0;
  transform: scale(2.5);
  transform-origin: 0 0;
}

/* for spinner loader */
.loader {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.loader .spinner-border {
  position: absolute;
  top: 40%;
  left: 50%;
}