.no {
  width: 3.5vw;
}

.sectorName {
  width: 200px;
}

.sectorFullName {
  width: 400px;
}

.action {
  width: 6vw;
  /* border-right: 1px solid #0097b1; */
}