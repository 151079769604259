html {
  max-height: 100vh;
}

.mainContent>div>div {
  height: 100%;
}

.card-header {
  background-color: #0097b1;
  color: #ffffff;
  font-weight: bold;
}

.btn-link {
  text-decoration: none;
  padding: 15px;
}

.error {
  color: red;
  text-align: center;
}

.row {
  margin-right: 0px;
}

.table>thead {
  background-color: #0097b1;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}

tbody tr {
  cursor: pointer;
  text-align: center;
}

.dashboard-list tbody tr th {
  padding: 0 !important;
}

.dashboard-list tbody tr td {
  padding: 0 !important;
}

th a {
  display: block;
  width: 100%;
  height: 100%;
}

th a:hover {
  color: black;
}

td a {
  display: block;
  width: 100%;
  height: 100%;
}

td a:hover {
  color: black;
}

.config-btn {
  width: 80px;
}

/* edit and delete icon for config module */
.actionIcon {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  padding: 0px;
}

.actionIcon svg {
  width: 24px;
  height: 24px;
  transition: 500ms;
  fill: white;
}

path {
  pointer-events: none;
}

.actionIcon:hover svg {
  fill: black;
}

.actionIcon.edit {
  background-color: #e49b0f;
}

.actionIcon.delete {
  background-color: red;
}

.toggleIcon {
  width: 23px;
  height: 23px;
  border: none;
  border-radius: 5px;
}

.actionIconOutline {
  width: 30px;
  height: 30px;
  padding: 3px 3px 3px 3px;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
}

.actionIconOutline svg {
  width: 24px;
  height: 24px;
  transition: 500ms;
  fill: white;
}

.actionIconOutline2 svg {
  width: 24px;
  height: 24px;
  transition: 500ms;
  padding-right: 3px;
  fill: white;
}

.actionIconOutline:hover svg {
  fill: black;
}

.actionIconOutline.bg-blue {
  background-color: #e49b0f;
}

.actionIconOutline.bg-red {
  background-color: red;
}

.actionIconOutline2.bg-grey {
  background-color: gray;
}

.actionTextBtn {
  width: -webkit-fill-available;
  height: fit-content !important;
  font-size: x-small;
  border: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  background-color: #e49b0f;
  color: white;
}

.actionTextBtn2-parent {
  cursor: pointer;
}

.actionTextBtn2 {
  width: -webkit-fill-available;
  height: 30px;
  border: 0;
  margin: 3px 0px 0px 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #0097b1;
  color: white;
  transition: 0.3s;
}

.actionTextBtn2-parent:hover .actionTextBtn2 {
  background-color: #0c899e;
  margin-top: 0px;
  transition: top 0.3s;
}

.dashboardActiveBtn {
  background-color: #e49b0f !important;
}

.rowsBtn {
  width: 87px;
  height: 30px;
}

.columnsBtn {
  width: 105px;
  height: 30px;
}

.reportZoomBtn {
  width: 35px;
  margin: 0;
}

/* tr selected row color when press edit icon */
.bg-tr {
  background-color: rgba(66, 74, 82, 0.15);
}

.form-control,
.form-select,
.modal-content,
.modal-header,
.btn {
  font-size: 12px;
}

.form-control,
.form-select {
  padding-top: 5px;
  padding-bottom: 5px;
}

body {
  /* overflow-x: hidden; */
  font-size: 13px;
}

.wj-label {
  font-size: 12px;
}

.wj-legend g rect,
.wj-legend rect {
  width: 7.5px;
  height: 7.5px;
}

.dashboardTab-parent {
  cursor: pointer;
}

.dashboardTab {
  width: -webkit-fill-available;
  height: 24px;
  border: 0;
  margin: 3px 0px 0px 0px;
  border-top-right-radius: 5px;
  /* border-top-left-radius: 5px; */
  background-color: #0097b1;
  color: white;
  transition: 0.3s;
}

.dashboardTab-parent:hover .dashboardTab {
  background-color: #0c899e;
  margin-top: 0px;
  transition: top 0.3s;
}

.dashboardActiveTab {
  background-color: #e49b0f !important;
}

.financial-data-summmary .wj-series-group ellipse {
  rx: 3;
  ry: 3;
}

.popUpBtn {
  border: 0px;
  background-color: #0080ff;
  color: #ffffff;
  border-radius: 5px;
  padding: 7.5px;
  margin-left: 10px;
  text-align: "center";
  vertical-align: "middle";
  box-shadow: 0px 2px 4px grey;
}

.popUpBtn:hover {
  background-color: #0040ff;
  transition: 0.2s;
}

.financial_quarter_data .wj-cell {
  text-align: center;
  font-size: 10px;
}

.financial-data-summmary .wj-title {
  font-size: 16px !important;
}

.costs-grid {
  height: auto;
  min-height: calc(100vh - 150px);
  position: relative;
}

.costs-grid .wj-flexgrid {
  margin: 0;
}

.costs-grid .wj-flexgrid:last-child {
  /* position: absolute; */
  /* top: -10px; */
  margin-top: -10px;
}

.costs-grid>.wj-flexgrid {
  height: 100%;
  margin: 5px 0px;
  min-height: 100%;
  max-height: calc(100vh - 150px);
  margin-top: 5px;
}

/* .row>*:last-child {
  margin-right: 0;
  padding-right: 0;
} */

@media (min-width: 768px) {
  .costs-gird>.wj-flexgrid {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .costs-gird>.wj-flexgrid {
    height: 100%;
  }
}

.alignCenter {
  text-align: center;
}

.cost-grid.move-scrollbar {
  width: calc(100% + 7px);
}

.cost-total {
  margin-right: 7px !important;
  width: 100%;
}

.toast-container {
  position: absolute;
  display: block;
  height: fit-content;
  min-height: 85px;
  width: fit-content;
  min-width: 350px;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
  overflow: hidden;
}

.toast {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: 1px solid black;
  z-index: 1000;
}

.invalid {
  border: 1px solid red;
  /* box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.25); */
}