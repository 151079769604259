a {
    color: inherit;
    text-decoration: none;
}

.side-bar::-webkit-scrollbar {
    display: none;
}

.side-bar {
    position: fixed;
    /* min-width: 50px; */
    height: calc(100vh - 37px);
    margin-top: 37px;
    background-color: rgb(0, 151, 177);
    color: white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 11;
    transition: 300ms;
}

@media (width <=768px) {
    .side-bar {
        position: absolute;
    }
}

.activeMenu {
    background-color: #E49B0F;
}

.menu-container {
    position: relative;
}

.sub-menu,
.sub,
.inner-sub {
    height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.sub-menu.expanded {
    height: fit-content;
    margin-left: 60px;
}

.sub {
    padding-left: 50px;
    overflow: hidden;
}

.sub.expanded {
    height: fit-content;
    visibility: visible;
}

.sub>div>div>a,
.sub>div>div>div,
.inner-sub>div>div>a {
    padding-left: 10px;
}

.inner-sub {
    padding-left: 20px;
}

.inner-sub.expanded {
    height: fit-content;
    visibility: visible;
}

.menu-item {
    position: relative;
}

.menu-item:hover {
    background: #0C899E;
}

.collapsed-submenu,
.collapsed-submenu-sub {
    position: fixed;
    background: rgb(0, 151, 177);
    height: fit-content;
    min-height: 50px;
    min-width: 150px;
    z-index: 11;
    visibility: hidden;
}

.collapsed-submenu-sub:hover {
    visibility: visible;
}

.collapsed-sub {
    min-height: 36px;
    position: relative;
    line-height: 36px;
    font-size: 14px;
    color: white;
    padding-left: 10px;
    cursor: pointer;
}

.collapsed-sub:hover {
    background-color: #0C899E;
}

.collapsed-sub-name {
    height: 36px;
}

.collapsed-sub-name:hover {
    background: #0C899E;
}

.collapsed-sub svg {
    height: 36px;
    margin-top: -36px;
}

.inner {
    width: 150px;
    height: auto;
    position: absolute;
    background-color: rgb(0, 151, 177);
    left: 150px;
    top: 0px;
    visibility: hidden;
}

.inner:hover {
    visibility: visible;
}