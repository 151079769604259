#sales-plan-grid.wj-flexgrid {
  height: fit-content;
  max-height: calc(100vh - 200px);
  margin: 5px 0px 5px 5px;
  font-size: 12px;
}

#sales-plan-grid.wj-flexgrid[data-state="expand"] {
  max-height: calc(100vh - 200px);
}

#sales-plan-grid.wj-flexgrid[data-state="collapse"] {
  max-height: calc(100vh - 120px);
}

.wj-flexgrid ::-webkit-scrollbar:horizontal {
  height: 15px;
}

#salesPlanSheet {
  margin-top: 3px;
  min-height: 540px;
  max-height: 700px;
}

@media (max-width: 992px) {
  #sales-plan-grid.wj-flexgrid {
    max-height: calc(100vh - 250px);
  }
}

.wj-header {
  text-align: center !important;
}

.wj-cell .v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: pre-wrap;
}

.column-picker-icon {
  cursor: pointer;
  color: #ff8754;
  margin: 3px;
}

.column-picker-div {
  display: none;
}

.wj-listbox.column-picker {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 10px;
  columns: 4;
  /* IE fallback */
  padding: 12px;
  margin-left: 12px;
  margin-top: 26px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.wj-listbox .wj-listbox-item > label {
  display: block;
  margin: 0 0 3px 0;
}

.wj-listbox .wj-listbox-item.wj-state-selected {
  background: transparent;
  color: inherit;
}

.wj-listbox .wj-listbox-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.wj-listbox .drop-marker {
  position: absolute;
  background: #0085c7;
  opacity: 0.5;
  pointer-events: none;
  z-index: 1000;
}

.cell-inactive {
  background-color: #eee;
}

.row-inactive {
  background-color: #c1c0c0;
}

.rowCount {
  margin-top: 0.3vw;
  margin-left: 0.3vw;
  background-color: #e49b0f;
  color: white;
  height: fit-content;
  min-height: 30px;
  padding: 6px 6px 6px 6px;
  border-radius: 5px;
}

.rowCountText {
  width: fit-content;
  line-height: 15px;
  margin: 0px;
}

.end {
  margin-left: 7vw;
}

.alignRight {
  text-align: right;
}

.accordion {
  margin-top: 3px;
  margin-left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}

.accordion__button {
  background-color: rgb(238, 238, 238);
  color: #444;
  cursor: pointer;
  padding: 2px 2px 2px 6px;
  width: 100%;
  text-align: end;
  border: none;
  border-radius: 5px 5px 0px 0px;
  font-size: 12px;
}

.accordion__button:hover {
  background-color: #ddd;
  border-radius: 5px 5px 0px 0px;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-135deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 0px 0px 6px 0px;
  animation: fadein 0.35s ease-in;
}

.accordion_title {
  float: left;
  font-weight: bold;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
