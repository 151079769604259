.dyanmic-dashboard {
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.dynamic-dashboard-menu {
    min-width: 45px;
    min-height: calc(100vh - 73px);
    margin: 0 10px 0 -8px;
    padding: 0 5px 0 0;
    display: flex;
    flex-direction: column;
    transition: 300ms;
    background-color: #a7a7a780;
}

.dynamic-dashboard-menu .expend-icon {
    width: 20px;
    height: 20px;
    float: right;
    margin: 5px 0;
    cursor: pointer;
}

.dynamic-dashboard-menu-item {
    display: flex;
    margin: 0 3px;
    min-width: 36px;
    width: 100%;
    height: 36px;
    align-items: center;
    overflow: hidden;
    margin: 2px 0;
    color: black;
    cursor: pointer;
    transition: 300ms;
}

.dynamic-dashboard-menu-item:hover {
    background-color: #a7a7a7;
}

.dynamic-dashboard-menu-item svg {
    min-width: 30px;
    min-height: 30px;
    margin: 0 5px;
}

.dynamic-dashboard-menu-name {
    padding: 0 0 0 10px;
    min-width: 214px;
}

.dynamic-dashboard-body {
    min-height: 100%;
    max-height: calc(100vh - 73px);
    overflow: auto;
    transition: 300ms all;
    container-type: inline-size;
}

.tile-container {
    background-color: rgba(230, 230, 230, 1);
    border-radius: 5px;
    padding: 5px;
    max-height: 400px;
    transition: 300ms all;
    /* box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
        0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07); */
}

.tile-container .wj-flexgrid {
    transition: 300ms all;
    border: none;
}

.tile-container .tile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    white-space: nowrap;
}

.tile-container .wj-header {
    background-color: rgb(0, 151, 177);
    color: white;
}

.tile-container .wj-flexchart,
.tile-container .wj-flexgrid {
    width: 100%;
    max-height: 350px;
}

.close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 300ms;
    margin-right: 3px;
    border-radius: 3px;
    cursor: pointer;
}

*:hover>div>.close-icon {
    opacity: 1;
    background-color: #E49B0F;
}

@media screen and (max-width: 768px) {
    .tile-container {
        width: 100%;
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) {
    .tile-container {
        width: 49%;
        margin: 5px 0.5%;
    }
}

@media screen and (min-width: 992px) {
    .tile-container {
        width: 32%;
    }
}

@media screen and (min-width: 1200px) {
    .tile-container {
        width: 24%;
    }
}

@container (width <=630px) {
    .tile-container {
        width: 99%;
        margin: 5px 0.5%
    }
}

@container (630px <=width <=920px) {
    .tile-container {
        width: 49%;
        margin: 5px 0.5%;
    }
}

.tile-container:last-child {
    flex: 1 1 auto;
}

.tile-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.tile-body div {
    width: 100%
}

.tile-body .radial-gauge {
    width: auto;
}

.drag-source {
    opacity: 0.4;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: rgba(145, 200, 248, 0.75);
}

.drag-over {
    border: 2px dashed #E49B0F;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 250ms;
}

.linear-gauge {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.linear-gauge-item {
    display: flex;
}