.wj-pivotgrid {
  max-height: 400px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.container > label {
  margin: 0 0 2em 2em;
}

body {
  margin-bottom: 24pt;
}
