#dashboardSheet {
  max-width: 94vw;
}

#dashboardSheet .wj-cell {
  text-align: center;
  font-size: x-small;
  white-space: normal;
}

#budgetStatusSheet {
  height: 623px;
  max-height: fit-content;
  min-height: fit-content;
  max-width: 94vw;
}

#budgetStatusSheet .wj-cell {
  text-align: center;
  font-size: 10px;
  white-space: normal;
}

.budgetStatusChart {
  height: 623px;
}

.budgetStatusChart + div {
  height: 300px;
}

.sectorStart {
  border-top: 1px solid black;
}
#finicialDataSheet {
  height: 575px;
  max-height: fit-content;
}
/* less than 800px */
@media only screen and (max-width: 768px) {
  [class*="financial-data-summmary"] {
    width: 100%;
    height: 100%;
  }
  [id="finicialDataSheet"] {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-height: 768px) {
  [id="dashboardSheet"] {
    height: 523px;
    max-height: 623px !important;
  }
}

/* greater than 1400px */
@media only screen and (min-height: 768px) {
  [id="dashboardSheet"] {
    height: 625px;
  }
}
