  #bcAnnualSheet {
    min-height: 75vh;
    padding: 0;
  }

  .bcAnnual .wj-cell {
    text-align: center;
    font-size: xx-small;
    white-space: normal;
  }

  #s .wj-flexsheet .wj-cell {
    padding: 1px;
  }

  .zoomClass {
    -webkit-transform: scale(1.5);
    -webkit-transform-origin: 0 0;
    -ms-transform: scale(1.5);
    -ms-transform-origin: 0 0;
    -moz-transform: scale(1.5);
    -moz-transform-origin: 0 0;
    transform: scale(1.5);
    transform-origin: 0 0;
  }

  .zoomClass1 {
    -webkit-transform: scale(2.5);
    -webkit-transform-origin: 0 0;
    -ms-transform: scale(2.5);
    -ms-transform-origin: 0 0;
    -moz-transform: scale(2.5);
    -moz-transform-origin: 0 0;
    transform: scale(2.5);
    transform-origin: 0 0;
  }