.wj-state-selected {
    stroke-dasharray: none !important;
}

.chart .wj-flexchart .wj-header .wj-title {
    font-weight: bold;
}

.chart .wj-flexchart .wj-label {
    font-weight: bold;
}

.chart div>div.wj-flexchart {
    background-color: rgb(250, 250, 250);
}

.wj-data-label {
    white-space: pre;
}

.chart div div {
    height: 500px;
}

.chart div {
    margin-bottom: 12px;
}

#pie.wj-flexchart .wj-header .wj-title,
#cNd.wj-flexchart .wj-header .wj-title {
    font-size: 14px !important;
}

.pieAndChart #pie {
    height: 250px;
    margin: 0;
}

.pieAndChart #cNd {
    height: 36.5%;
}

@media only screen and (max-width: 769px) {
    .pieAndChart #pie {
        height: auto;
    }

    .pieAndChart #cNd {
        height: auto;
        min-height: 250px;
    }
}

.total {
    margin-left: 10px;
}