.dashboard-container {
    height: calc(100vh - 74px);
}

.dashboard-body {
    height: calc(100vh - 73px);
    overflow: auto;
}

.dashboard-card {
    margin-bottom: 8px;
    font-weight: bold;
    height: 100%;
}

.dashboard-card p {
    margin: 0;
    line-height: 18px;
}

.dashboard-card-title {
    width: 100%;
    height: 24px;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.dashboard-card-body {
    min-height: 60px;
    color: white;
    padding: 2.5px;
    height: fit-content;
    border-radius: 5px;
}

.dashboard-card-body p {
    font-size: 11px !important;
}


.title+.wj-flexchart {
    padding: 0;
    /* height: 240px; */
}

.dashboard-first-row {
    /* height: calc(100vh - 160px - 50vh); */
    height: 50%;
    min-height: 270px;
    /* max-height: 500px; */
    position: relative;
}

.sales-charts {
    /* height: calc(100vh - 156px - 60vh); */
    height: 50%;
    min-height: 200px;
    /* max-height: 400px; */
}

.title div a svg,
.sales-charts a svg {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
}

.wj-data-label {
    font-size: 10px;
    width: fit-content;
}

.sales-charts .wj-title {
    font-size: 13px !important;
}

.percent {
    padding-left: 18px;
}

.card-progress {
    padding-top: 0.5em;
}

.card-progress-container {
    width: 100%;
    height: 7px !important;
    border: none;
    background-color: white;
    border-radius: 7px;
    overflow: hidden;
}

.card-progress-container .card-progress-value {
    content: "";
    height: 100%;
}

.dashboard-table {
    height: calc(100% - 22.5px);
    min-height: 240px;
    font-size: 12px;
}

.table-title-number {
    text-align: center;
}

.table-number {
    text-align: right;
}

.title {
    margin-bottom: 3px;
}

.title p {
    margin: 0px;
}

.title div a,
.sales-charts a {
    border: none;
    background-color: #E49B0F;
    width: 26px;
    height: 26px;
    fill: black;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    display: block;
    text-align: center;
    line-height: 26px;
    margin-left: auto;
}

.sales-charts a {
    margin-left: auto;
}

.title div a svg,
.sales-charts a svg {
    width: 20px;
    height: 20px;
}

.wj-flexchart {
    background-color: rgb(250, 250, 250);
}

.dashboard-chart-container,
.dashboard-chart-container-budget {
    background-color: rgba(230, 230, 230, 1);
    border-radius: 5px;
    padding: 5px 10px;
    transition: 300ms all;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-chart-container-budget>.title {
    width: 100%;
}

.dashboard-chart-body-budget {
    height: 87%;
    width: 100%;
}

.dashboard-chart-body-budget .wj-flexchart {
    height: 100%;
    max-height: 100%;
    width: auto;
    padding: 0px;
    margin: 0px;
}

.dashboard-chart-header {
    /* height: 30px; */
    width: 100%;
}

.dashboard-chart-header p {
    margin: 0px;
    /* line-height: 30px; */
}

.dashboard-chart-body {
    height: 87%;
    width: 100%;
}

.dashboard-chart-body .wj-flexchart {
    height: 100%;
    width: auto;
    padding: 0px;
    margin: 0px;
}

@media screen and (min-width: 768px) {
    .dashboard-chart-container {
        height: 300px;
        /* max-height: 300px; */
        margin: 5px 0;
    }
}

@media screen and (max-width: 768px) {
    .dashboard-chart-container {
        height: 300px;
        margin: 5px 0;
    }

    .dashboard-chart-container-budget {
        height: 300px;
        margin: 5px 0;
    }
}

@media screen and (min-width: 992px) {
    .dashboard-chart-container {
        margin: 0px;
        height: 100%;
        min-height: 200px;
    }

    .dashboard-chart-container-budget {
        height: 100%;
        /* min-height: 240px; */
    }
}